import React from "react"
import { Container, Col, Row } from "src/ui"
import { ImageData } from "ExtendiApp"
import { MarkdownContent } from "../MarkdownContent"
import InViewMonitor from "react-inview-monitor"

interface Props {
  imageCard1: ImageData
  imageCard2: ImageData
  blocks: Block[]
}

type Block = {
  title: string
  text: string
  image1?: ImageData
  image2?: ImageData
}

export const PageBlocksAlt: React.FC<Props> = ({ imageCard1, imageCard2, blocks = [] }) => {
  return (
    <section className={"section section-blocks pt-0"}>
      <Container>
        <Row className="mosaic mb-md-xl">
          <Col sm="6">
            {imageCard1 && imageCard1.imageUrl && (
              <img src={imageCard1.imageUrl} alt={imageCard1.altText} className="img-fluid shadow-lg" />
            )}
          </Col>
          <Col sm="6">
            {imageCard2 && imageCard2.imageUrl && (
              <img src={imageCard2.imageUrl} alt={imageCard2.altText} className="img-fluid shadow-lg" />
            )}
          </Col>
        </Row>

        {blocks.map(({ title, text, image1, image2 }, index) => (
          <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
            <div className="block-element">
              <Col sm={{ size: 10, offset: 1 }}>
                <div className="text-center">
                  <h2 className="pb-2">{title}</h2>
                  <div className="mb-5 text-darkbrown">
                    <MarkdownContent content={text} />
                  </div>
                </div>
              </Col>
              <Row className="mosaic" key={index}>
                {image1 && (
                  <Col sm="6">
                    <img src={image1.imageUrl} alt={image1.altText} className="img-fluid shadow-lg" />
                  </Col>
                )}
                {image2 && (
                  <Col sm="6">
                    <img src={image2.imageUrl} alt={image2.altText} className="img-fluid shadow-lg" />
                  </Col>
                )}
              </Row>
            </div>
          </InViewMonitor>
        ))}
      </Container>
    </section>
  )
}
